
<template>
<comprehensive>
 <div class="ddmg-mall">
     <div class="ddmg-mall-banner">
         <img src="../../assets/img/stockbanner.png" alt="大大买钢">
     </div>
     <stock/>
 </div>
 </comprehensive>
</template>

<script>

import stock from "./components/stock.vue";
import comprehensive from "../../components/comprehensive.vue";
import { mapActions } from "vuex";
export default {
  name:'mall',
  components: {
    stock,comprehensive
  },
  data() {
    return {

    };
  },
  methods: {
    ...mapActions("ddmg/mall", [
      "mallSearchData","mallListData"
    ]),
  },
  mounted(){
      //  this.mallSearchData().then(res=>{
      //       console.log(res)
      //  })
      //  this.mallListData().then(res=>{
      //       console.log(res)
      //  })
  }
};
</script>

<style lang="scss" scoped>
.ddmg-mall {
    width: 100%;
    height:auto;
    background-color: #f3f5f9ff;
}
.ddmg-mall-banner{
    margin:0 auto;
    width:1200px;
}
</style>
