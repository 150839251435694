<template>
  <section
    class="stock-content"
    v-loading="pageLoading"
  >
    <!--全部结果-->
    <div
      class="stock-condition"
      v-if="searchConditionsList.length > 0"
    >
      <div class="stock-condition-title">
        <p>全部结果</p>
      </div>
      <div class="stock-condition-list">
        <div
          class="stock-condition-item"
          v-for="(items, index) in searchConditionsList"
          :key="index"
        >
          <p>{{ searchListHeadTitle[items.name] }}:{{ items.item }}</p>
          <img
            src="../../../assets/img/4@3x.png"
            alt="大大买钢"
            @click="onDeleteCondtion(index)"
          />
        </div>
        <div
          class="stock-condition-clear pointer"
          @click="onDeleteCondtionAll"
        >
          清空条件
        </div>
      </div>
    </div>
    <!--数据明细-->
    <div
      :class="{'stock-detailed': true, 'stock-detailed-up': isOpenDown == true,}"
      :style="{ height: allSearchListHeight + 'px' }"
    >
      <div
        class="stock-detailed-list"
        v-for="(item, name, index) in searchList"
        :key="index"
      >
        <div class="detailed-list-title">{{ searchListHeadTitle[name] }}</div>
        <div
          class="detailed-list-Shell"
          :ref="name"
          :style="{ height: (clickHeight[index].childHeight + 1) * 46 + 'px' }"
        >
          <div
            v-for="(listItem, num) in item"
            :class="{'detailed-list-item': true, active: listItem.isActive == true,}"
            :key="num"
            @click="onConditions(listItem, name, num)"
          >
            <p>
              {{ listItem.name }}
            </p>
          </div>
        </div>
        <!--请确认规格确定格子，放到class="detailed-list-mored"之中-->
        <div class="detailed-list-mored">
          <div
            class="detailed-list-specs"
            v-if="name == 'specificationsList'"
          >
            <el-input
              v-model="searchInput"
              placeholder="请确认规格"
              style="padding:0px 12px"
            ></el-input>
          </div>
          <div
            class="detailed-list-sure"
            v-if="name == 'specificationsList'"
          >
            <button
              class="specifications_input_search_confirm pointer"
              @click="onMultipleChoice"
            >
              确认
            </button>
          </div>
          <div
            class="detailed-list-sure multiple_choice"
            v-if="name == 'originPlaceList' || name =='warehouseCityList'"
          >
            <button class="pointer">
              <img
                src="../../../assets/img/add.svg"
                alt="大大买钢"
              />支持多选
            </button>
          </div>
        </div>

        <div
          class="detailed-list-img dis-none"
          @click="updowns(name, index, false)"
        >
          <div :class="{ 'list-img-mored': true, 'list-img-mored-up': clickHeight[index].childHeight != 0, }"></div>
        </div>
      </div>
    </div>

    <div
      :class="{'stock-detailed-open': true, 'stock-detailed-open-up': isOpenDown == true,}"
      @click="OpenDown"
    >
      <span v-if="isOpenDown">
        <p>展开筛选<img
            src="../../../assets/img/10@3xr.png"
            alt="大大买钢"
          /></p>
      </span>
      <span v-else>
        <p>收起筛选<img
            src="../../../assets/img/10@3xr.png"
            alt="大大买钢"
          /></p>
      </span>
    </div>

    <!--产品列表-->
    <!--产品列表-->
    <div class="stock-product">
      <div class="stock-product-header">
        <div class="product-header-time">
          <p>更新时间</p>
          <div class="header-icon pointer">
            <img
              src="../../../assets/img/2688.png"
              alt="大大买钢"
              @click="descOrasc('time')"
              :class="{ timeSort: timeSort == true }"
            />
          </div>
        </div>
        <div class="product-header-price">
          <p>价格</p>
          <div class="header-icon pointer pointer">
            <img
              src="../../../assets/img/2688.png"
              alt="大大买钢"
              @click="descOrasc('price')"
              :class="{ priceSort: priceSort == true }"
            />
          </div>
        </div>
        <div class="product-header-total">
          <p>
            共<span>{{ pagination.total }}件</span>商品
          </p>
        </div>
        <div class="product-header-num">
          <img
            class="pointer"
            src="../../../assets/img/10@3xl.png"
            alt="大大买钢"
            @click="gotoNext(pagination.pages - 1)"
          />
          <p>
            <span>{{ pagination.pages }}</span><span>/</span><span>{{ pagination.totalPage }}</span>
          </p>
          <img
            class="pointer"
            src="../../../assets/img/10@3x.png"
            alt="大大买钢"
            @click="gotoNext(pagination.pages + 1)"
          />
        </div>
      </div>
      <div
        class="sock-product-list-box"
        v-if="allProductList.length > 0"
      >
        <!--一类产品信息开始 clickHeight[index].childHeight != 0-->
        <div
          class="sock-product-bigitem"
          v-for="(items, index) in allProductList"
          :key="index"
          :style="'height:' + (allProductListHeight[index] * 91 + 64) + 'px'"
        >
          <div class="product-bigitem-nav">
            <div class="bigitem-nav-img">
              <img
                src="https://ddmallimg.ddmg.com/public/assets/img/index/mall/16.svg"
                alt="大大买钢"
              />
            </div>
            <div class="bigitem-nav-title">{{ items.originPlace }} {{ items.productName }} {{ items.material }} {{ items.specifications }}</div>
            <div
              class="bigitem-nav-icon"
              :class="{
                'bigitem-nav-icon-up': allProductListHeight[index] == 0,
              }"
              @click="onAccordion(index)"
            ></div>
          </div>
          <!--一条产品信息开始-->

          <div
            class="sock-product-item"
            v-for="(item, idx) in items.items"
            :key="idx"
          >
            <div class="product-name">
              <p>
                {{ item.warehouseName }}
                <span v-if=" item.availWeight > 0 || item.listingSource == 2">库存充足</span>
                <span v-else>库存不足</span>
              </p>
            </div>
            <div class="product-company">
              <div
                class="company-name"
                @click="goDetail(item)"
              >
                <p>{{ item.companyName }}</p>
                <img
                  src="../../../assets/img/10@3x.png"
                  alt="大大买钢"
                />
              </div>

              <div class="company-tell">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  :content="item.contactName +':'+ item.contactPhone"
                >

                  <p
                    slot="reference"
                    style="display:flex;align-items: center"
                  >
                    <img
                      src="https://ddmallimg.ddmg.com/public/assets/img/index/mall/phone.svg "
                      style="margin-right:5px"
                      alt="大大买钢"
                    />
                    联系商家
                  </p>
                </el-popover>

              </div>
            </div>
            <div class="prodcut-label">
              <p>直营店铺</p>
              <p>一票制/多票制</p>
            </div>
            <div
              class="product-price"
              style="margin-right:30px"
            >
              <div style="text-align: right;font-size: 20px;font-weight: 900;">￥{{ item.price }}</div>
              <!-- <p>{{item.updateTime.substring(5,item.updateTime.length-3)}}</p> -->
              <div style="text-align: right">{{ item.createTime2 }}</div>
            </div>
            <div
              class="product-order"
              style="margin-left:20px"
            >
              <div @click="addOrder(item.id)">
                <p>一键下单</p>
              </div>
              <div
                @click="addGWC(item)"
                class="product-gwc-img"
              ></div>
            </div>
          </div>

          <!--一条产品信息结束-->
        </div>
        <!--一类产品信息结束-->
      </div>

      <div
        class="div_404 empty-tips goods-empty-tips"
        v-else
      >
        <div class="div_404_left"></div>
        <div class="div_404_right">
          <h3 class="empty-tips-message">抱歉~该商铺暂未发布资源</h3>
          <h4>
            可以点击<span style="color: #666666">“我要求购”</span>去发布求购信息，客服会尽快联系您。
          </h4>
          <button
            class="btnqiug"
            style="cursor: pointer"
            @click="$router.push({ name: 'toBuy' })"
          >
            我要求购
          </button>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="stock-paging">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :total="pagination.total"
        :page-size="num"
        @current-change="gotoNext"
        :current-page="pagination.pages"
      >
      </el-pagination>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import util from "@/libs/util.js";
import storage from "@/utils/storage";
export default {
  components: {},
  data () {
    return {
      num: 10,
      pages: 1,
      rows: 10,
      isOpenDown: false,
      allSearchListHeight: 0, //总高度

      searchInput: "", //规格确定框的内容

      priceSort: false, //fakse降序，true升序
      timeSort: false, //fakse降序，true升序
      clickHeight: [
        {
          childHeight: 0,
        },
        {
          childHeight: 0,
        },
        {
          childHeight: 0,
        },
        {
          childHeight: 0,
        },
        {
          childHeight: 0,
        },
        {
          childHeight: 0,
        },
      ],
      clickHeightNum: 0,
      searchList: {
        productNameList: [],
        materialList: [],
        specificationsList: [],
        originPlaceList: [],
        warehouseCityList: [],
        warehouseNameList: [],
      },
      searchListHeadTitle: {
        productNameList: "品名",
        materialList: "材质",
        specificationsList: "规格",
        originPlaceList: "产地",
        warehouseCityList: "存货地",
        warehouseNameList: "仓库",
      },
      searchConditionsList: [],
      allProductList: [], //现货资源列表
      allProductListHeight: [], //现货资源高度
      pagination: {}, //总数 页码

      keywords: "",
      formData: {
        productName: "",      //品名
        material: "",         //材质
        warehouseName: "",      //仓库
        specifications: [],     //规格
        originPlace: [],       //产地
        warehouseCity: [],   //存货地
      },
      sortables: [
        {
          name: "price",
          order: "DESC",
        },
        {
          name: "createTime",
          order: "DESC",
        },
      ],
    };
  },
  methods: {
    ...mapActions("ddmg/gwc", ["getAddGWC"]),
    ...mapActions("ddmg/mall", ["mallSearchData", "mallListData"]),

    changWord (curVal, oldVal) {
      this.onDeleteCondtionAll();
      this.keywords = curVal;
      this.getListData();
    },
    //监听城市切换
    changeCity (curVal, oldVal) {
      this.onDeleteCondtionAll();
      this.searchConditionsList.push({
        item: curVal,
        name: "warehouseCityList"
      })
      //  this.getListData();
    },
    //翻页
    gotoNext (val) {
      //分页
      if (val < 1 || val > this.pagination.totalPage) {
        this.$message.error("当前没有更多页面了");
        return;
      }
      this.pages = val;
      this.getListData();
      //点击翻页 输入传入筛选条件，目前还没有字段
    },

    getListData () {
      let userInfo = null;
      if (storage.getStorage("userInfo")) {
        userInfo = storage.getStorage("userInfo");
      }
      let userId = '';
      if (userInfo) {
        userId = userInfo.userid;
      }
      //列表数据的初始化
      let body = {
        productName: this.formData.productName,      //品名
        material: this.formData.material,         //材质
        warehouseName: this.formData.warehouseName,      //仓库
        specifications: this.formData.specifications,     //规格
        originPlace: this.formData.originPlace,       //产地
        warehouseCity: this.formData.warehouseCity,   //存货地
        keyword: this.keywords,
        pages: this.pages,
        rows: this.rows,
        sortables: this.sortables,
        userId: userId ? userId : "",
      };
      this.mallListData(body).then((res) => {
        if (res.msgcode == 1) {
          this.allProductList = res.resultdata.data;
          this.allProductListHeight = [];
          this.allProductList.map((item) => {
            this.allProductListHeight.push(item.items.length);
            return (item.goodsResourceOutDtolength = item.items.length);
          });
          this.pagination = res.resultdata;

        }
      });
    },
    //监听筛选参数变更
    changeSearchList (curVal, oldVal) {
      Object.keys(this.searchList).forEach((key) => {
        this.searchList[key].map((items) => {
          for (let i of this.searchConditionsList) {
            if (items.name == i.item) {
              items.isActive = true;
              break;
            } else {
              items.isActive = false;
            }
          }
        });
      });
      this.formData.productName = '';      //品名
      this.formData.material = '';           //材质
      this.formData.warehouseName = '';        //仓库
      this.formData.specifications = [];       //规格
      this.formData.originPlace = [];         //产地
      this.formData.warehouseCity = [];     //存货地
      this.searchConditionsList.forEach(obj => {
        if (obj.name == 'productNameList') {
          this.formData.productName = obj.item;
        } else if (obj.name == 'materialList') {
          this.formData.material = obj.item;
        } else if (obj.name == 'warehouseNameList') {
          this.formData.warehouseName = obj.item;
        } else if (obj.name == 'specificationsList') {
          this.formData.specifications.push(obj.item);
        } else if (obj.name == 'originPlaceList') {
          this.formData.originPlace.push(obj.item);
        } else if (obj.name == 'warehouseCityList') {
          this.formData.warehouseCity.push(obj.item);
        }
      })
      this.getListData();
    },
    //单选条件
    onConditions (val, name) {
      if (name != 'originPlaceList' && name != 'warehouseCityList' && name != 'specificationsList') {
        this.danxuan(val, name);
      } else {
        this.duoxuan(val, name);
      }
    },
    //单选
    danxuan (val, name) {
      if (this.searchConditionsList.find(obj => obj.name == name)) {
        this.searchConditionsList.find(obj => obj.name == name).item = val.name
      } else {
        this.searchConditionsList.push({ name, item: val.name, });
      }
      this.changeSearchList();
    },
    //多选
    duoxuan (val, name) {
      if (!this.searchConditionsList.find(obj => obj.item == val.name)) {
        this.searchConditionsList.push({
          name,
          item: val.name,
        });
      }
      this.changeSearchList();
    },
    //单项的展开和收起
    updowns (name, index, onMore) {
      if (this.clickHeight[index].childHeight > 0) {

        this.clickHeight[index].childHeight = 0;
        if (this.isOpenDown) {
          this.allSearchListHeight = 46 * 3;
        } else {
          this.allSearchListHeight = 46 * 6;
        }
      } else {
        let childWith = this.$refs[name][0].childNodes;

        let num = 0;
        for (let i of childWith) {
          num += i.offsetWidth + 22.5;
        }
        this.clickHeightNum = Math.ceil(num / 680);
        if (this.clickHeightNum <= 1 && onMore == false) {
          this.$message.warning("当前下拉无更多选项");
        } else if (this.clickHeightNum <= 1 && onMore == true) {
          this.clickHeightNum = 0;
        } else {
          this.clickHeight[index].childHeight = this.clickHeightNum;
          this.allSearchListHeight += 46 * this.clickHeightNum;
        }
      }
    },
    //筛选条件里的X
    onDeleteCondtion (index) {
      this.searchConditionsList.splice(index, 1);
    },
    //清空条件
    onDeleteCondtionAll () {
      this.searchConditionsList.splice(0, this.searchConditionsList.length);
      this.searchInput = "";
      Object.keys(this.searchList).forEach((key) => {
        this.searchList[key].map((items) => {
          items.isActive = false;
        });
      });
    },
    //筛选框的展开和收起
    OpenDown () {
      this.isOpenDown = !this.isOpenDown;
      console.log(this.isOpenDown);
      if (this.isOpenDown) {
        if (this.allSearchListHeight > 46 * 6) {
          for (let i of this.clickHeight) {
            i.childHeight = 0;
          }
        }
        this.allSearchListHeight = 46 * 3;
      } else {
        if (this.allSearchListHeight > 46 * 3) {
          this.allSearchListHeight += 46 * 3;
        } else {
          this.allSearchListHeight = 46 * 6;
        }
      }
    },
    goDetail (item) {
      let routeData = this.$router.resolve({ path: '/shop?id=' + item.companyId });
      window.open(routeData.href, '_blank');
    },

    addGWC (item) {
      console.log(item);
      let token = this.isToken();
      if (token) {
        this.getAddGWC(item).then(res => {
          if (res.msgcode === 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
      }
    },
    //一键下单
    addOrder (id) {
      let token = this.isToken();
      if (token) {
        this.$router.push({ name: "cart", query: { id: id, type: 1 } });
      }
    },
    //排序
    descOrasc (types) {
      if (types == "price") {
        this.priceSort = !this.priceSort;
        this.sortables[0].order = this.sortables[0].order == "DESC" ? "ASC" : "DESC";
      } else {
        this.timeSort = !this.timeSort;
        this.sortables[1].order = this.sortables[1].order == "DESC" ? "ASC" : "DESC";
      }
      this.getListData();
    },
    //列表的收起下拉
    onAccordion (val) {
      //20220125修改拉伸框 92
      if (this.allProductListHeight[val] > 0) {
        this.allProductListHeight[val] = 0;
      } else {
        this.allProductListHeight[val] =
          this.allProductList[val].goodsResourceOutDtolength;
      }
      this.$forceUpdate();
    },
    //规格输入框确定按钮
    onMultipleChoice () {
      if (this.searchInput) {
        for (let i of this.searchList["specificationsList"]) {
          if (String(i.name).indexOf(this.searchInput) != -1) {
            if (!this.searchConditionsList.find(obj => obj.item == i.name)) {
              this.searchConditionsList.push({
                name: "specificationsList",
                item: i.name,
              });
            }
          }
        }
      } else {
        this.$message.error("请输入正确的格式");
      }
    },
    setAllsearchtHeight () {
      this.allSearchListHeight = 46 * 6;
    },
  },
  created () {
    if (this.$route.query.keyword) {
      this.keywords = this.$route.query.keyword;
    }
    //筛选栏目的初始化
    this.mallSearchData().then((res) => {
      if (res.msgcode == 1) {
        Object.keys(res.resultdata).forEach((key) => {
          if (this.searchList[key]) {
            res.resultdata[key].map((item) => {
              let str = {
                name: item,
                isActive: false,
              };
              this.searchList[key].push(str);
            });
          }
        });
        if (this.searchConditionsList.length == 0) {
          this.searchConditionsList.push({
            item: this.cityName,
            name: "warehouseCityList"
          })
        }
        this.setAllsearchtHeight();
        // this.changeSearchList();
        // this.getListData();
      }
    });
  },
  computed: {
    ...mapState("ddmg/home", {
      cityName: (state) => state.cityName,
    }),
    ...mapState("ddmg/mall", {
      pageLoading: (state) => state.pageLoading,
      keyword: (state) => state.keyword,
    }),
  },
  watch: {
    searchConditionsList: "changeSearchList",
    cityName: "changeCity",
    keyword: 'changWord'
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
.stock-content .priceSort,
.timeSort {
  transform: rotate(180deg);
}
.stock-content .el-checkbox__input.is-checked + .el-checkbox__labe {
  color: #e73352 !important;
}

.stock-content .is-checked * {
  color: #e73352 !important;
}

.stock-content .is-checked .el-checkbox__inner {
  background-color: #e73352 !important;
  border-color: #e73352 !important;
}

.stock-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f5f9ff;
}

.stock-content .el-collapse-item__content {
  padding-bottom: 0px !important;
}

.stock-content .stock-condition {
  width: 1200px;
  background-color: #fff;
  display: flex;
  margin: 20px auto;
}

.stock-content .stock-condition-title {
  margin-left: 16px;
  font-size: 14px;
  color: #333333ff;
  font-weight: 400;
  width: 74px;
  line-height: 52px;
}

.stock-content .stock-condition-title {
  margin-right: 11.5px;
}

.stock-condition .stock-condition-list {
  display: flex;
  flex-wrap: wrap;
  width: 1120px;
  height: auto;
  align-items: center;
}

.stock-content .stock-condition .stock-condition-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e73352ff;
  margin-right: 12px;
  border: 1px solid #e73352ff;
  max-width: 200px;
  height: 24px;
  border-radius: 2px;
  /*! line-height: 24px; */
  box-sizing: border-box;
  margin-top: 11.5px;
  margin-bottom: 11.5px;
}

.stock-content .stock-condition .stock-condition-item p {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e73352ff;
  /*! height: 24px; */
  /*! line-height: 24px; */
  padding: 3.5px;
  border-radius: 2px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  cursor: pointer;
  caret-color: rgba(0, 0, 0, 0);
}

.stock-content .stock-condition .stock-condition-item img {
  width: 7.6px;
  margin: 0px 3.5px 0 0px;
  /*! vertical-align: middle; */
}

.stock-content .stock-condition .stock-condition-clear {
  font-size: 14px;
  color: #e73352ff;
  font-weight: 400;
}

.stock-content .stock-detailed {
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #ecececff;
  // height:calc(46*6px);
  border-bottom: none;
  transition: all 1s ease;
  overflow: hidden;
}

.stock-detailed-up {
  //height: 140px!important;
  transition: all 1s ease;
}

.stock-content .stock-product {
  width: 1200px;
  background-color: #fff;
  margin: 20px auto;
}

.stock-content .stock-product-header {
  background-color: #f9f9f9ff;
  display: flex;
  height: 43px;
  line-height: 43px;
  width: 100%;
  border: 1px solid #ecececff;
}

.stock-content .stock-product-header .product-header-time {
  display: flex;
  align-items: center;
  width: 92.5px;
  justify-content: center;
  border-right: 1px solid #f1f1f1ff;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
}

.stock-content .stock-product-header .header-icon {
  display: flex;
  width: 12px;
  flex-direction: column;
  margin-left: 4px;
  justify-content: space-between;
}

.stock-content .stock-product-header .header-icon img {
  width: 100%;
}

.stock-content .stock-product-header .product-header-price {
  display: flex;
  align-items: center;
  width: 92.5px;
  justify-content: center;
  border-right: 1px solid #f1f1f1ff;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
}

.stock-content .stock-product-header .product-header-total {
  display: flex;
  align-items: center;
  width: 910.5px;
  justify-content: end;
  border-right: 1px solid #f1f1f1ff;
  font-size: 14px;
  color: rgba(153, 153, 153, 1);
  font-weight: 400;
}

.stock-content .stock-product-header .product-header-total p {
  margin-right: 12.5px;
}

.stock-content .stock-product-header .product-header-total p span {
  color: rgba(51, 51, 51, 1);
}

.stock-content .stock-product-header .product-header-num {
  display: flex;
  align-items: center;
  width: 92.5px;
  text-align: center;
  justify-content: center;
}

.stock-content .stock-product-header .product-header-num img {
  width: 6px;
  height: 9px;
}

.stock-content .stock-product-header .product-header-num p {
  margin: 0 8px;
}

.stock-content .stock-product-header .product-header-num p span:nth-child(1) {
  color: rgba(239, 33, 71, 1);
}

.stock-content .sock-product-bigitem {
  transition: all 1s ease;
  overflow: hidden;
}

.stock-content .sock-product-bigitem .product-bigitem-nav {
  width: 1200px;
  height: 57px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1ff;
}

.stock-content .sock-product-bigitem .product-bigitem-nav .bigitem-nav-img {
  width: 20px;
  height: 20px;
  margin-left: 12px;
  margin-right: 8.03px;
}

.stock-content .sock-product-bigitem .product-bigitem-nav img {
  width: 100%;
}

.stock-content .sock-product-bigitem .product-bigitem-nav .bigitem-nav-title {
  width: 1102px;
}

.stock-content .sock-product-bigitem .product-bigitem-nav .bigitem-nav-icon {
  width: 16px;
  height: 16px;
}

.stock-content .sock-product-item {
  height: 92px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(241, 241, 241, 1);
  background-color: #f9f9f9;
  position: relative;
}

.stock-content .sock-product-item:hover {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(255, 167, 167, 0.16);
  z-index: 999;
}

.stock-content .sock-product-item img {
  width: 80px;
  height: 80px;
  margin: 24px 16px 24px 24px;
}

.stock-content .sock-product-item img {
  width: 80px;
  height: 80px;
  margin: 24px 16px 24px 24px;
}

.stock-content .product-name {
  width: 240.5px;
  margin-left: 5%;
}

.stock-content .product-company {
  width: 254px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.stock-content .product-company .company-name {
  font-size: 15px;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 21px;
  cursor: pointer;
}

.stock-content .product-company .company-name p {
  min-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.stock-content .product-company .company-name img {
  width: 6px;
  height: 9px;
  margin-left: 4px;
}

.stock-content .product-company .company-tell {
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 13px;
  color: rgba(153, 153, 153, 1);
  position: relative;

  /*! text-align: left; */
}

.stock-content .product-company .company-tell img {
  width: 16px;
  height: 16px;
  margin: 0;
  margin-right: 4px;
}

/*需要隐藏的电话号码开始*/
.stock-content .product-company .company-tell .company-phone {
  width: 104px;
  height: 25px;
  position: absolute;
  top: -35px;
}

.stock-content .product-company .company-tell .company-phone p {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
  text-align: center;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
}

.stock-content .product-company .company-tell .company-phone i {
  width: 8px;
  height: 15px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-radius: 0% 0% 90% 90%;
  background-color: #fff;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 2px;
}

/*需要隐藏的电话号码结束*/

.stock-content .sock-product-item .prodcut-label {
  width: 157px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
}

.stock-content .sock-product-item .prodcut-label p {
  width: 85px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  border-radius: 4px;
}

.stock-content .sock-product-item .prodcut-label p:nth-child(1) {
  border: 1px solid rgba(252, 146, 72, 0.5);
  color: rgba(252, 146, 72, 1);
}

.stock-content .sock-product-item .prodcut-label p:nth-child(2) {
  margin-top: 4px;
  border: 1px solid rgba(61, 125, 255, 0.5);
  color: rgba(61, 125, 255, 1);
}

.stock-content .sock-product-item .product-price {
  width: 181px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 400;
}

.stock-content .sock-product-item .product-price p:nth-child(1) {
  width: 98px;
  height: 28px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 900;
  color: rgba(51, 51, 51, 1);
}

.stock-content .sock-product-item .product-price p:nth-child(2) {
  width: 78px;
  line-height: 18px;
  margin-top: 5px;

  color: rgba(153, 153, 153, 1);
  text-align: right;
}

.stock-content .sock-product-item .product-order {
  width: 243.5px;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  color: rgba(239, 33, 71, 1);
  font-weight: 400;
}

.stock-content .sock-product-item .product-order p {
  width: 110px;
  height: 36px;
  text-align: center;
  border: 1px solid rgba(221, 221, 221, 1);
  line-height: 36px;
  padding: 0 27px;
  cursor: pointer;
}

.stock-content .sock-product-item .product-order p:hover {
  background-color: #ef2147ff;
  color: #fff;
}

.stock-content .sock-product-item .product-order img:nth-child(1) {
  margin-top: 4px;
}

.stock-content .sock-product-item .product-order img {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(221, 221, 221, 1);
  /*! line-height: 29px; */
  padding: 10px;
  margin: 0;
  margin-left: 2px;
}

.stock-content .sock-product-item .product-gwc-img {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(221, 221, 221, 1);
  /*! line-height: 29px; */
  padding: 10px;
  margin: 0;
  margin-left: 2px;
  background: url("../../../assets/img/gwch.svg") no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
}

.stock-content .sock-product-item .product-gwc-img:hover {
  background-color: #ef2147ff !important;
  background: url("../../../assets/img/gwcb.svg") no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
}

.stock-content .stock-detailed .stock-detailed-list {
  display: flex;
  border-bottom: 1px solid #f1f1f1ff;
}

.stock-content .detailed-list-title {
  background-color: #f8f8f8ff;
  padding-left: 16px;
  font-size: 14px;
  color: #333333ff;
  font-weight: 700;
  width: 74px;
  line-height: 46px;
}

.detailed-filler {
  width: 74px;
  background-color: #f8f8f8ff;
  padding-left: 16px;
}

.detailed-list-item-updown {
  font-size: 13px;
  font-weight: 400;
  margin: 0 11.5px;
  height: 46px;
  line-height: 46px;
}

.stock-content .detailed-list-Shell-active {
  height: 150px !important;
}

.stock-content .detailed-list-Shell {
  display: flex;
  width: 700px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  flex-wrap: wrap;
  height: 46px;
  transition: all 1s ease;
}

.stock-content .detailed-list-item {
  color: #333333ff;
  font-size: 13px;
  font-weight: 400;
  margin: 0 11.5px;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
}

.stock-content .detailed-list-item.active {
  color: #e73352ff;
}

.stock-content .detailed-list-item:hover {
  color: #e73352ff;
}

.stock-content .detailed-list-specs input {
  width: 138px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: #999999ff;
  border: 1px solid #ddddddff;
  padding-left: 6px;
  margin-right: 4px;
  box-sizing: border-box;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-sure button {
  min-width: 66px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #666666ff;
  border: 1px solid #ddddddff;
  padding-left: 2px;
  background-color: #fff;
  padding: 0px 6px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-img img {
  margin-left: 30px;
  width: 16px;
  height: 16px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-choice {
  width: 64px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  color: #666666ff;
  border: 1px solid #ddddddff;
  text-align: center;
  margin-left: 142px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-choice img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  display: inline-block;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-choice.none {
  border: none !important;
  width: 66px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-select img {
  margin-left: 30px;
  width: 16px;
  height: 16px;
}

.stock-content .stock-detailed-open {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #999999ff;
  height: 33px;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
  width: 1200px;
  margin: 0 auto;
}

.stock-detailed-open-up img {
  transform: rotate(360deg) !important;
  transition: all 1s ease;
}

.stock-content .stock-detailed-open img {
  transform: rotate(180deg);
  width: 9px;
  height: 6px;
  margin-left: 4px;
}

.stock-content .sock-product-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(241, 241, 241, 1);
}

.stock-content .sock-product-item:hover {
  background-color: rgba(249, 249, 249, 1);
}

.stock-content .sock-product-item img {
  width: 80px;
  height: 80px;
  margin: 24px 16px 24px 24px;
}

.stock-content .sock-product-item img {
  width: 80px;
  height: 80px;
  margin: 24px 16px 24px 24px;
}

.stock-content .product-name {
  width: 240.5px;
}

.stock-content .product-name p:nth-child(1) {
  color: rgba(51, 51, 51, 1);
  font-size: 15px;
  font-weight: 400;
  width: 240.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.stock-content .product-name p:nth-child(2) {
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  font-weight: 400;
  width: 240.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  margin-top: 8px;
}

.stock-content .product-company {
  width: 254px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.stock-content .product-company .company-name {
  font-size: 15px;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
}

.stock-content .product-company .company-tell {
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 13px;
  color: rgba(153, 153, 153, 1);
  position: relative;

  /*! text-align: left; */
}

.stock-content .product-company .company-tell img {
  width: 16px;
  height: 16px;
  margin: 0;
  margin-right: 4px;
}

/*需要隐藏的电话号码开始*/
.stock-content .product-company .company-tell .company-phone {
  width: 104px;
  height: 25px;
  position: absolute;
  top: -35px;
}

.stock-content .product-company .company-tell .company-phone p {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
  text-align: center;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
}

.stock-content .product-company .company-tell .company-phone i {
  width: 8px;
  height: 15px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-radius: 0% 0% 90% 90%;
  background-color: #fff;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 2px;
}

/*需要隐藏的电话号码结束*/

.stock-content .sock-product-item .prodcut-label {
  width: 157px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
}

.stock-content .sock-product-item .prodcut-label p {
  width: 85px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  border-radius: 4px;
}

.stock-content .sock-product-item .prodcut-label p:nth-child(1) {
  border: 1px solid rgba(252, 146, 72, 0.5);
  color: rgba(252, 146, 72, 1);
}

.stock-content .sock-product-item .prodcut-label p:nth-child(2) {
  margin-top: 4px;
  border: 1px solid rgba(61, 125, 255, 0.5);
  color: rgba(61, 125, 255, 1);
}

.stock-content .sock-product-item .product-price {
  width: 155px;
  display: flex;
  flex-direction: column;
}

.stock-content .sock-product-item .product-price p:nth-child(1) {
  width: 98px;
  height: 28px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 900;
  color: rgba(51, 51, 51, 1);
}

.stock-content .sock-product-item .product-price p:nth-child(2) {
  width: 78px;
  line-height: 18px;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  text-align: right;
}

.stock-content .sock-product-item-box {
  transition: all 1s ease;
}

.stock-content .sock-product-item .product-order {
  width: 243.5px;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  color: rgba(239, 33, 71, 1);
  font-weight: 400;
}

.stock-content .sock-product-item .product-order p {
  width: 110px;
  height: 36px;
  text-align: center;
  border: 1px solid rgba(221, 221, 221, 1);
  line-height: 36px;
  padding: 0 27px;
}

.stock-content .sock-product-item .product-order img:nth-child(1) {
  margin-top: 4px;
}

.stock-content .sock-product-item .product-order img {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(221, 221, 221, 1);
  /*! line-height: 29px; */
  padding: 10px;
  margin: 0;
  margin-left: 2px;
}

.stock-content .stock-paging {
  display: flex;
  justify-content: center;
  margin-bottom: 43px;
  align-items: center;
}

.stock-content .stock-paging .stock-paging-total {
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
  margin-right: 14.33px;
}

.stock-content .stock-paging .stock-paging-left {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
}

.stock-content .stock-paging img {
  width: 6px;
  height: 11px;
  background-color: #fff;
  border-radius: 4px;
}

.stock-content .stock-paging .stock-paging-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-content .stock-paging .stock-paging-list li {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
}

.stock-content .stock-paging ul li.active {
  background-color: #f0f0f0;
  color: rgba(38, 38, 38, 1);
}

.stock-content .stock-paging .stock-paging-goto {
  display: flex;
  justify-content: center;
}

.stock-content .stock-paging .stock-paging-right {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
}

.stock-content .stock-paging .stock-paging-goto {
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.stock-content .stock-paging .stock-paging-goto p {
  margin-right: 14.33px;
}

.stock-content .stock-paging .stock-paging-goto input {
  width: 44px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgba(38, 38, 38, 0.17);
}

.stock-content .stock-paging .stock-paging-goto p:nth-child(3) {
  margin-left: 14.33px;
}

.stock-content .stock-detailed .stock-detailed-list.more .detailed-list-specs {
  margin-left: 125px;
  align-self: flex-start;
  margin-top: 11.5px;
}

.stock-content .stock-detailed .stock-detailed-list.more .detailed-list-sure {
  align-self: flex-start;
  margin-top: 11.5px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-specs input {
  width: 138px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #999999ff;
  border: 1px solid #ddddddff;
  padding-left: 2px;
  /*! margin-right: 4px; */
  box-sizing: border-box;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-mored {
  display: flex;
  width: 208px;
  /*! line-height: 46px; */
  margin-top: 7px;
  justify-content: right;
  margin-left: 120px;
}

.stock-content
  .stock-detailed
  .stock-detailed-list
  .detailed-list-mored
  button
  img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-sure button {
  min-width: 66px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #666666ff;
  border: 1px solid #ddddddff;
  padding-left: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  padding: 0px 12px;
}

.stock-content .stock-detailed .stock-detailed-list .detailed-list-img {
  align-self: flex-start;
  margin-top: 15.5px;
}

.stock-content
  .stock-detailed
  .stock-detailed-list
  .detailed-list-img
  .list-img-mored {
  margin-left: 30px;
  width: 16px;
  height: 16px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background: url("../../../assets/img/downh.svg") no-repeat;
}

.list-img-mored {
  transition: all 1s ease;
  transform: rotate(0deg);
}

.list-img-mored:hover {
  background: url("../../../assets/img/downred.svg") no-repeat !important;
}

.list-img-mored-up {
  background: url("../../../assets/img/uph.svg") no-repeat;
  transform: rotate(180deg);
  transition: all 1s ease;
}

.list-img-mored-up:hover {
  background: url("../../../assets/img/downred.svg") no-repeat !important;
}

.stock-content .sock-product-bigitem .product-bigitem-nav .bigitem-nav-icon {
  transition: all 1s ease;
  transform: rotate(180deg);
  background: url("../../../assets/img/downh.svg") no-repeat;
}

.stock-content
  .sock-product-bigitem
  .product-bigitem-nav
  .bigitem-nav-icon:hover {
  background: url("../../../assets/img/downred.svg") no-repeat !important;
}

.stock-content .sock-product-bigitem .product-bigitem-nav .bigitem-nav-icon-up {
  background: url("../../../assets/img/downh.svg") no-repeat;
  transform: rotate(0deg);
  transition: all 1s ease;
}

.stock-content
  .sock-product-bigitem
  .product-bigitem-nav
  .bigitem-nav-icon-up:hover {
  background: url("../../../assets/img/downred.svg") no-repeat !important;
}

.stock-content .div_404 {
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  text-align: center;
}

.stock-content .div_404_left {
  width: 350px;
  height: 300px;
  background: url(https://ddmallimg.ddmg.com/public/assets/img/index/mall/404.png)
    no-repeat center;
  background-size: 100% 100%;
  float: left;
  margin-left: 220px;
  margin-top: 100px;
}

.stock-content .div_404_right {
  float: left;
  height: 300px;
  margin-top: 100px;
  margin-left: 20px;
  text-align: left;
}

.stock-content .div_404_right h3 {
  font-size: 24px;
  color: #ff6200;
  margin-top: 68px;
  text-align: left;
  font-weight: 400;
}

.stock-content .div_404_right h4 {
  font-size: 16px;
  color: #999999;
  font-weight: 400;
}

.stock-content .div_404_right .btnqiug {
  width: 210px;
  height: 42px;
  text-align: center;
  background-color: #ff6200;
  color: #ffffff;
  border: none;
  font-size: 16px;
  margin-top: 10px;
  outline: none;
}
</style>
